@media only screen and (max-width: 1100px) {
  #about {
      display: flex;
      flex-wrap: wrap;
      margin-left: 3%!important;
      margin-right: 3%!important;
  }
  #about div {
      padding: 10px;
      margin: 0 auto!important;
  }
  #about img {
      max-width: 300px!important;
  }

}
@media only screen and (max-width: 575px) {
  #skills .container {
    margin-left: 10px!important;
    width: auto;
  }
  #skills > div > div > div > div.col-3 {
    flex: none;
    max-width: none;
  }
  #USD {
    padding-left: 5%!important;
  }
}

@media only screen and (max-width: 390px) {
  .mr-3 {
    width:300px;
  }
  #USD {
    margin-top: 20px!important;
    height: 450px!important;
  }
  #Hello {
    min-height: 320px!important;
  }
}

#USD {
  color: white;
  text-shadow: 2px 2px 2px black;
  padding-left: 45%;
  margin-top: 50px;
  height: 400px;
}

#Hello {
  margin-bottom: 0;
  min-height: 220px;
  padding-bottom: 0;
}

